.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
  }
  
  .profile-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
    margin-bottom: 20px;
  }
  
  .profile-info p {
    margin: 0;
  }
  
  .profile-image {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .profile-image-input {
    margin-top: 10px;
  }
  .purchased-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .purchased-item-image {
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    margin-right: 10px;
  }
  
  .purchased-item-title {
    font-size: 16px;
  }